import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Row, Col, Form } from 'react-bootstrap'
// import FloatInput from './FloatInput'
import AutoHeightText from './AutoHeightText'

const bgcolors = [
  'rgb(35, 61, 99, 0.1)',
  'rgb(51, 107, 139, 0.1)',
  'rgb(234, 112, 11, 0.1)',
  'rgb(240, 181, 31, 0.1)',
  'rgb(131, 152, 77, 0.1)',
]

function ColorForm({ setting }) {
  const { module, index, handleInputChange } = setting
  const [
    name = '- -',
    advance = [],
    weak = [],
    price1 = '- -',
    price2 = '- -',
    sp = '- -',
    audience = '- -',
    pr = 0,
    pa = 0,
  ] = module

  const [floatSetting, setfloatSetting] = useState({
    visibility: 'hidden',
    top: '0px',
    left: '0px',
    width: '0px',
    value: '',
    onBlur: () => {},
    onChange: () => {},
  })

  return (
    <Row
      className="list-row print-break"
      style={{
        backgroundColor: bgcolors[index % 5],
      }}
    >
      <div
        className="d-flex data-title col-lg-1"
        style={{ width: '5%' }}
        data-title="序號"
      >
        {index + 1}
      </div>
      <div
        className="d-flex flex-fill data-title"
        style={{ width: '9%' }}
        data-title="品牌名稱"
      >
        <Form.Control
          className="my-auto py-0 fw-regular fs-7 text-dai text-center"
          style={{
            backgroundColor: 'transparent',
            borderColor: 'transparent',
          }}
          value={name}
          onDoubleClick={(e) => {
            const { offsetTop, offsetLeft, offsetWidth } = e.target
            setfloatSetting({
              top: offsetTop,
              left: offsetLeft,
              width: offsetWidth,
              visibility: 'visible',
              value: name,
              onBlur: () =>
                setfloatSetting({
                  ...floatSetting,
                  visibility: 'hidden',
                }),
              onChange: (value) => {
                handleInputChange('product', index, [
                  value,
                  advance,
                  weak,
                  price1,
                  price2,
                  sp,
                  audience,
                  pr,
                  pa,
                ])
              },
            })
          }}
          onChange={(e) =>
            handleInputChange('product', index, [
              e.target.value,
              advance,
              weak,
              price1,
              price2,
              sp,
              audience,
              pr,
              pa,
            ])
          }
        />
      </div>
      <Col xs={2} className="d-flex flex-fill data-title" data-title="優勢">
        <div className="flex-1">
          {advance.map((a, i) => (
            <Row key={i} className="d-flex">
              <Col className="list-item d-flex border-0">
                <p className="list-number">{i + 1}.</p>
                <AutoHeightText
                  setting={{
                    onChange: (value) => {
                      handleInputChange('product', index, [
                        name,
                        advance.map((item, j) => (i === j ? value : item)),
                        weak,
                        price1,
                        price2,
                        sp,
                        audience,
                        pr,
                        pa,
                      ])
                    },
                    value: a,
                  }}
                />
              </Col>
            </Row>
          ))}
        </div>
      </Col>
      <Col xs={2} className="d-flex flex-fill data-title" data-title="劣勢">
        <div className="flex-1">
          {weak.map((a, i) => (
            <Row key={i} className="d-flex">
              <Col className="list-item d-flex border-0">
                <p className="list-number">{i + 1}.</p>
                <AutoHeightText
                  setting={{
                    onChange: (value) => {
                      handleInputChange('product', index, [
                        name,
                        advance,
                        weak.map((item, j) => (i === j ? value : item)),
                        price1,
                        price2,
                        sp,
                        audience,
                        pr,
                        pa,
                      ])
                    },
                    value: a,
                  }}
                />
              </Col>
            </Row>
          ))}
        </div>
      </Col>
      <Col xs={1} className="d-flex flex-fill data-title" data-title="價格區間">
        <div className="d-flex align-items-start">
          <span className="dollar-sign">$</span>
          <div className="print-textarea">{price1}</div>
          <Form.Control
            className="number-input print-none"
            type="number"
            value={price1}
            onChange={(e) =>
              handleInputChange('product', index, [
                name,
                advance,
                weak,
                e.target.value,
                price2,
                sp,
                audience,
                pr,
                pa,
              ])
            }
          />
          <span className="dollar-sign">~</span>
          <div className="print-textarea">{price2}</div>
          <Form.Control
            className="number-input print-none"
            type="number"
            value={price2}
            onChange={(e) =>
              handleInputChange('product', index, [
                name,
                advance,
                weak,
                price1,
                e.target.value,
                sp,
                audience,
                pr,
                pa,
              ])
            }
          />
        </div>
      </Col>
      <Col xs={1} className="d-flex flex-fill data-title" data-title="產品特點">
        <AutoHeightText
          setting={{
            onChange: (value) => {
              handleInputChange('product', index, [
                name,
                advance,
                weak,
                price1,
                price2,
                value,
                audience,
                pr,
                pa,
              ])
            },
            value: sp,
          }}
        />
      </Col>
      <Col xs={1} className="d-flex flex-fill data-title" data-title="主要客群">
        <AutoHeightText
          setting={{
            onChange: (value) => {
              handleInputChange('product', index, [
                name,
                advance,
                weak,
                price1,
                price2,
                sp,
                value,
                pr,
                pa,
              ])
            },
            value: audience,
          }}
        />
      </Col>
    </Row>
  )
}

function SuggestProduct({ setting }) {
  const { module, handleInputChange } = setting

  return (
    <Col className="h-100 w-100 pt-2 d-flex flex-column">
      <div
        className="px-3 d-none d-lg-flex"
        style={{
          backgroundColor: 'white',
        }}
      >
        <div style={{ width: '5%' }}>序號</div>
        <div style={{ width: '9%' }} className="flex-fill">
          品牌名稱
        </div>
        <Col xs={2} className="flex-fill">
          優勢
        </Col>
        <Col xs={2} className="flex-fill">
          劣勢
        </Col>
        <Col xs={1} className="flex-fill">
          價格區間
        </Col>
        <Col xs={1} className="flex-fill">
          產品特點
        </Col>
        <Col xs={1} className="flex-fill">
          主要客群
        </Col>
      </div>
      {module && module.map
        ? module.map((m, index) => (
            <ColorForm
              key={index}
              setting={{
                module: m,
                index,
                handleInputChange,
              }}
            />
          ))
        : [1, 2, 3, 4, 5].map((key, index) => (
            <ColorForm
              key={index}
              setting={{
                module: [],
                index,
                handleInputChange,
              }}
            />
          ))}
    </Col>
  )
}

ColorForm.propTypes = {
  setting: PropTypes.shape().isRequired,
}

SuggestProduct.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default SuggestProduct
