import Home from './Home'
import Register from './Register'
import Module1 from './Module1'
import Module2 from './Module2'
import Module3 from './Module3'
import Module4 from './Module4'
import Blockeditor from './Blockedit'
import Test from './Test'

export { Home, Register, Module1, Module2, Module3, Module4, Blockeditor, Test }
