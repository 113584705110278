import React from 'react'
import { Row, Col } from 'react-bootstrap'

function Column() {
  return (
    <div className="articleBlock">
      <Row>
        <Col>
          <div contentEditable="true" suppressContentEditableWarning="true">
            50%
          </div>
        </Col>
        <Col>
          <div contentEditable="true" suppressContentEditableWarning="true">
            50%
          </div>
        </Col>
        <Col>
          <div contentEditable="true" suppressContentEditableWarning="true">
            50%
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default Column
