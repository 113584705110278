/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react'
import '../stylesheets/editor.scss'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUpDownLeftRight, faTrash } from '@fortawesome/free-solid-svg-icons'
import { v4 as uuid } from 'uuid'
import Warn from '../components/Warn'
import { TextArea, ImageEditor, ArticleEditor, Column, XYDrag } from '../editor'

const ITEMS = [
  {
    id: uuid(),
    type: 'Headline',
    content: 'Headline',
  },
  {
    id: uuid(),
    type: 'Image',
    content: 'https://picsum.photos/1920/1080',
  },
  {
    id: uuid(),
    type: 'Column',
    content: '',
  },
  {
    id: uuid(),
    type: 'Article',
    content: {
      title: '文章標題',
      content: '內容',
    },
  },
  {
    id: uuid(),
    type: 'Graphic',
    content: '',
  },
]

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

const copy = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source)
  const destClone = Array.from(destination)
  const item = sourceClone[droppableSource.index]

  destClone.splice(droppableDestination.index, 0, { ...item, id: uuid() })
  return destClone
}

const handleEmotionChange = () => {}

function ThemeBlock() {
  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: 'none',
    background: isDragging ? 'transparent' : '#DDDDDD',
    ...draggableStyle,
  })

  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? 'transparent' : 'transparent',
  })

  return (
    <div className="themeSide">
      <div className="themeSide-header">Theme</div>
      <Droppable droppableId="ITEMS" isDropDisabled>
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}
          >
            {ITEMS.map((item, index) => (
              <Draggable
                key={`${item.id}`}
                draggableId={`${item.id}`}
                index={index}
              >
                {(dragProvided, dragSnapshot) => (
                  <React.Fragment key={index}>
                    <div
                      className="snippet-item"
                      ref={dragProvided.innerRef}
                      {...dragProvided.draggableProps}
                      {...dragProvided.dragHandleProps}
                      style={getItemStyle(
                        dragSnapshot.isDragging,
                        dragProvided.draggableProps.style
                      )}
                    >
                      {item.type}
                    </div>
                    {dragSnapshot.isDragging && (
                      <div className="snippet-item clone-item">{item.type}</div>
                    )}
                  </React.Fragment>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </div>
  )
}

function Blockedit() {
  const emotions = [1, 1, 1, 1, 1]
  const [svgSize] = useState({
    width: 1000,
    height: 500,
  })

  // 儲存卡片資料
  // const [stage, setStage] = useState({
  //   [uuid()]: [],
  // })

  const [stage, setStage] = useState({
    [uuid()]: [
      {
        id: '1',
        type: 'Headline',
        content: '標題',
      },
      {
        id: '2',
        type: 'Headline',
        content: '內容',
      },
      {
        id: '3',
        type: 'Article',
        content: {
          title: '核心技術',
          content:
            '新材料應用探索使用新材料，如碳纖維，以提升吉他的音質和耐用性。電子擴音技術發展更先進的電子擴音技術，以提供更好的音效和音量控制。數位音樂製作整合數位音樂製作技術，使吉他與數位音樂製作工具更加兼容。',
        },
      },
    ],
  })

  const [showWarn, setShowWarn] = useState({
    show: false,
    func: () => {},
  })

  // const checkstage = () => {
  //   console.log(stage)
  // }

  // useEffect(checkstage, [stage])

  const cardDelete = (label, id) => {
    setStage({
      [label]: stage[label].filter((item) => item.id !== id),
    })
  }

  const onDragEnd = (result) => {
    const { source, destination } = result

    if (!destination) {
      return
    }

    switch (source.droppableId) {
      case destination.droppableId:
        setStage({
          [destination.droppableId]: reorder(
            stage[destination.droppableId],
            result.source.index,
            result.destination.index
          ),
        })

        break
      case 'ITEMS':
        setStage({
          [destination.droppableId]: copy(
            ITEMS,
            stage[destination.droppableId],
            source,
            destination
          ),
        })

        break
      default:
        break
    }
  }

  const dragAreaStyle = (isDraggingOver) => ({
    border: isDraggingOver ? '1px dashed #4099ff' : '1px solid #ddd',
  })

  const dragItemStyle = (isDragging, draggableStyle) => ({
    userSelect: 'none',
    border: isDragging ? '1px dashed #FF0000' : 'none',
    ...draggableStyle,
  })

  return (
    <div className="editorContainer">
      <DragDropContext onDragEnd={onDragEnd}>
        <ThemeBlock />
        <div className="editorContent">
          {Object.keys(stage).map((list) => (
            <Droppable key={list} droppableId={list}>
              {(cprovided, csnapshot) => (
                <div
                  ref={cprovided.innerRef}
                  style={dragAreaStyle(csnapshot.isDraggingOver)}
                >
                  {stage[list].length ? (
                    stage[list].map((item, index) => (
                      <Draggable
                        key={item.id}
                        draggableId={item.id}
                        index={index}
                      >
                        {(dragProvided, dragSnapshot) => (
                          <div
                            ref={dragProvided.innerRef}
                            {...dragProvided.draggableProps}
                            style={dragItemStyle(
                              dragSnapshot.isDragging,
                              dragProvided.draggableProps.style
                            )}
                            className="copy-item"
                          >
                            <div className="itemControl">
                              <Button
                                className="hand"
                                {...dragProvided.dragHandleProps}
                              >
                                <FontAwesomeIcon icon={faUpDownLeftRight} />
                              </Button>
                              <Button
                                onClick={() =>
                                  setShowWarn({
                                    show: true,
                                    func: () => cardDelete(list, item.id),
                                  })
                                }
                              >
                                <FontAwesomeIcon icon={faTrash} />
                              </Button>
                            </div>
                            {item.type === 'Headline' ? (
                              <TextArea
                                setting={{
                                  value: item.content,
                                }}
                              />
                            ) : item.type === 'Image' ? (
                              <ImageEditor
                                setting={{
                                  value: item.content,
                                }}
                              />
                            ) : item.type === 'Article' ? (
                              <ArticleEditor
                                setting={{
                                  title: item.content.title,
                                  content: item.content.content,
                                }}
                              />
                            ) : item.type === 'Column' ? (
                              <Column />
                            ) : item.type === 'Graphic' ? (
                              <XYDrag
                                setting={{
                                  emotions,
                                  handleEmotionChange,
                                  ...svgSize,
                                }}
                              />
                            ) : (
                              <div
                                contentEditable="true"
                                suppressContentEditableWarning="true"
                              >
                                {item.content}
                              </div>
                            )}
                          </div>
                        )}
                      </Draggable>
                    ))
                  ) : (
                    <div className="panel">Drop items here</div>
                  )}
                  {cprovided.placeholder}
                </div>
              )}
            </Droppable>
          ))}
        </div>
      </DragDropContext>
      <Warn
        setting={{
          show: showWarn.show,
          handleClose: (value) => {
            if (value) showWarn.func()
            setShowWarn({
              show: false,
              func: () => {},
            })
          },
        }}
      />
    </div>
  )
}

export default Blockedit
