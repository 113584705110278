import React from 'react'
import PropTypes from 'prop-types'
import { Button, Image, Form } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUpload } from '@fortawesome/free-solid-svg-icons'
import { v4 as uuid } from 'uuid'

function ImageEditor({ setting }) {
  const { value } = setting
  const fileId = uuid()

  return (
    <div className="articleBlock text-start">
      <a href={value} target="_blank" rel="noreferrer">
        <Image className="img-fluid" src={value} />
      </a>
      <div className="blockSetting">
        <Button title="上傳">
          <Form.Label htmlFor={fileId}>
            <FontAwesomeIcon icon={faUpload} />
          </Form.Label>
        </Button>
        <Form.Control
          id={fileId}
          name="file"
          type="file"
          onChange={() => {}}
          onClick={(e) => {
            e.stopPropagation()
          }}
          className=""
          style={{
            visibility: 'hidden',
            width: '0px',
            height: '0px',
          }}
        />
      </div>
    </div>
  )
}

ImageEditor.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default ImageEditor
