import React from 'react'
import PropTypes from 'prop-types'

function ArticleEditor({ setting }) {
  const { title, content } = setting

  return (
    <div className="articleBlock text-start">
      <h2 contentEditable="true" suppressContentEditableWarning="true">
        {title}
      </h2>
      <p contentEditable="true" suppressContentEditableWarning="true">
        {content}
      </p>
    </div>
  )
}

ArticleEditor.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default ArticleEditor
