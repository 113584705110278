import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Form } from 'react-bootstrap'

function TextArea({ setting }) {
  const { value } = setting
  const [temp, setTemp] = useState(value)

  const textAreaRef = useRef(null)

  const resizeTextArea = () => {
    textAreaRef.current.style.height = 'auto'
    textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`
  }

  useEffect(resizeTextArea, [temp])

  return (
    <div className="textarea-group">
      <div className="print-textarea">{temp}</div>
      <Form.Control
        ref={textAreaRef}
        className=""
        style={{
          resize: 'none',
          overflowY: 'hidden',
          border: 'none',
        }}
        value={temp}
        as="textarea"
        onChange={(e) => {
          setTemp(e.target.value)
        }}
      />
    </div>
  )
}

TextArea.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default TextArea
