/* eslint-disable no-promise-executor-return */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useContext, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import moment from 'moment'
import 'moment-timezone'
import {
  Form,
  InputGroup,
  Image,
  Container,
  Row,
  Col,
  Button,
  Modal,
} from 'react-bootstrap'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faEye,
  faEyeSlash,
  faEdit,
  faTrashAlt,
  faCirclePlus,
  faBars,
  faCircleExclamation,
  faCheckSquare,
  faSearch,
  faFileArrowUp,
  faCloudArrowDown,
} from '@fortawesome/free-solid-svg-icons'
import {
  AuthContext,
  // ToastContext,
  DraftContext,
} from '../components/ContextProvider'
// import apiServices from '../services/apiServices'
import file from '../services/file'
import { LoadingButton, Loading } from '../components'
import { logoFull } from '../asset'

function Warn({ setting }) {
  const { size = 'md', show = false, handleClose } = setting
  const { drafts } = useContext(DraftContext)
  const target = drafts ? drafts.find((d) => d.draft_id === show) : null

  return (
    <Modal
      style={{ zIndex: '1501' }}
      size={size}
      show={show !== false}
      onHide={() => handleClose(false)}
    >
      <Modal.Header
        className="AccFormModal justify-content-center text-center pt-4"
        closeButton
      >
        <Modal.Title>
          <h4>系統訊息</h4>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex AccformCard">
        <div className="assPermis w-100">
          <Form className="px-2 Form-card flex-grow-1">
            <Form.Group className="px-5 lh-md text-center text-dai">
              <FontAwesomeIcon
                icon={faCircleExclamation}
                style={{ height: '5rem' }}
                className="my-4"
              />
              <Form.Label className="w-100 fs-5 fw-bold text-center pb-4">
                確定要刪除
                {show && target
                  ? target.setting.name ||
                    `專案${target.setting.id || target.draft_id}`
                  : '專案'}
                嗎？
              </Form.Label>
            </Form.Group>
          </Form>
        </div>
      </Modal.Body>
      <Modal.Footer className="sendForm justify-content-center py-3">
        <Button variant="secondary" onClick={() => handleClose(false)}>
          取消
        </Button>
        <Button variant="dai" onClick={() => handleClose(show)}>
          確定
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

function Home() {
  const { auth, setAuth } = useContext(AuthContext)
  // const { setToast } = useContext(ToastContext)
  const navigate = useNavigate()

  const [reveal, setReveal] = useState(false)
  const fields = [
    {
      label: '帳號',
      type: 'text',
      name: 'email',
      placeholder: '帳號',
    },
    {
      label: '密碼',
      type: 'password',
      name: 'password',
      placeholder: '密碼',
    },
  ]
  const [data, setData] = useState({
    email: '',
    password: '',
  })
  const onDataChange = (event) => {
    setData({ ...data, [event.target.name]: event.target.value })
  }
  const handleLogin = async () => {
    // const { token } = await apiServices.login(data)
    // if (!token) {
    //   setToast({ show: true, text: '登 入 失 敗' })
    //   return
    // }
    document.cookie = `token=fakeToken; Domain=${window.location.hostname}; Path=/;`
    // const { user } = await apiServices.me()
    const user = {
      user_id: 1,
      name: 'dAIVinci',
      email: 'daivinci@wavenet.com.tw',
      setting: {
        admin: true,
      },
    }
    setAuth({
      authed: true,
      ...user,
    })
  }

  const {
    drafts,
    setDraftId,
    setDraft,
    setDrafts,
    handleDraftAdd,
    handleDraftDelete,
  } = useContext(DraftContext)

  const [editing, setEditing] = useState('')
  const [focus, setFocus] = useState(false)
  const [tempSearch, setTempSearch] = useState('')
  const [search, setSearch] = useState('')

  const grid = 0
  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: 'none',
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,
    cursor: isDragging ? 'grab' : 'pointer',
    ...draggableStyle,
  })

  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? 'transparent' : 'transparent',
    padding: grid,
    width: '100%',
  })

  const [showWarn, setshowWarn] = useState(false)
  const [loading, setloading] = useState(false)

  const ref = useRef(null)
  const handleCsvUpload = () => {
    if (!ref.current) return
    file.readFile(
      ref.current.files[0],
      ['module1', 'module2', 'module3', 'module4'],
      (res) => {
        handleDraftAdd(res)
      }
    )
  }

  return (
    <Container
      className="h-100 w-100 d-flex flex-column position-relative"
      onClick={() => setEditing('')}
    >
      <Loading
        setting={{
          time: 50,
          show: loading,
          step: '下載圖片中......',
          handleInterrupt: () => setloading(false),
        }}
      />
      {auth.authed ? (
        <>
          <Row className="py-3">
            <Col>
              <h5 className="text-nowrap text-dai">專案列表</h5>
            </Col>
          </Row>
          <div className="control-bar d-md-flex text-start mb-3 mb-lg-0">
            <InputGroup className="col me-2 mb-3 mb-md-0">
              <Form.Control
                placeholder="請輸入關鍵字以搜尋..."
                aria-label="Recipient's username"
                aria-describedby="basic-addon2"
                value={tempSearch}
                onChange={(event) => setTempSearch(event.target.value)}
                onFocus={() => setFocus(true)}
                onBlur={() => setFocus(false)}
                onKeyDown={(event) => {
                  if (
                    event.key === 'Enter' &&
                    !event.nativeEvent.isComposing &&
                    focus
                  )
                    setSearch(tempSearch)
                }}
              />
              <Button
                variant="outline-dai"
                id="button-addon2"
                title="搜 尋"
                onClick={() => setSearch(tempSearch)}
              >
                <FontAwesomeIcon icon={faSearch} />
              </Button>
            </InputGroup>
            <Button
              className="ms-auto me-2 control-btn"
              onClick={() => ref.current.click()}
              variant="outline-dai"
            >
              上傳專案&ensp;
              <FontAwesomeIcon
                icon={faFileArrowUp}
                style={{
                  cursor: 'pointer',
                }}
                className="ms-auto my-auto fs-6"
                title="上傳專案"
              />
            </Button>
            <input
              ref={ref}
              style={{
                visibility: 'hidden',
                width: '0',
                height: '0',
              }}
              type="file"
              id="xlsx"
              name="xlsx"
              accept=".xlsx"
              onChange={(e) => {
                handleCsvUpload(e)
                e.target.value = ''
              }}
            />
            <Button
              className="ms-auto control-btn"
              onClick={() => handleDraftAdd()}
              variant="outline-dai"
            >
              新建專案&ensp;
              <FontAwesomeIcon
                icon={faCirclePlus}
                style={{
                  cursor: 'pointer',
                }}
                className="ms-auto my-auto fs-6"
                title="新增專案"
              />
            </Button>
          </div>
          <div className="pt-3 text-dai d-none d-lg-flex">
            <Col xs={1} className="">
              編號
            </Col>
            <Col>專案名稱</Col>
            <Col>備註</Col>
            <Col xs={2}>建立時間</Col>
            <Col xs={2}>更新時間</Col>
            <div className="controlCol">操作</div>
          </div>
          <DragDropContext
            onDragEnd={(e) => {
              const result = Array.from(drafts)
              const [removed] = result.splice(e.source.index, 1)
              result.splice(e.destination.index, 0, removed)
              setDrafts(result)
            }}
          >
            <Droppable droppableId="droppable" direction="vertical">
              {(dropProvided, dropSnapshot) => (
                <div
                  {...dropProvided.droppableProps}
                  ref={dropProvided.innerRef}
                  style={getListStyle(dropSnapshot.isDraggingOver)}
                  className="wrapper"
                >
                  {drafts ? (
                    drafts
                      .filter(({ setting }) => {
                        const { name, remark } = setting
                        return (
                          !search ||
                          (name && name.includes(search)) ||
                          (remark && remark.includes(search))
                        )
                      })
                      .map(
                        ({ draft_id, setting, created_on, updated_on }, i) => (
                          <Draggable
                            key={`${draft_id}`}
                            draggableId={`${draft_id}`}
                            index={i}
                          >
                            {(dragProvided, dragSnapshot) => (
                              <div
                                ref={dragProvided.innerRef}
                                {...dragProvided.draggableProps}
                                {...dragProvided.dragHandleProps}
                                style={{
                                  ...getItemStyle(
                                    dragSnapshot.isDragging,
                                    dragProvided.draggableProps.style
                                  ),
                                  // className="position-absolute text-dai fs-7"
                                  // top: `${5}%`,
                                  // left: `${3 + i * 32}%`,
                                  // height: '85%',
                                  // width: '30%',
                                }}
                              >
                                <Row
                                  key={draft_id}
                                  className="list-row"
                                  onClick={() => setDraftId(draft_id)}
                                  style={{
                                    backgroundColor: 'rgb(35, 61, 99, 0.1)',
                                  }}
                                >
                                  <Col
                                    lg={1}
                                    className="d-flex data-title"
                                    data-title="編號"
                                  >
                                    <Form.Control
                                      className="my-auto h-75 py-0 fw-regular fs-7 text-dai text-center"
                                      style={{
                                        backgroundColor: 'transparent',
                                        borderColor: 'transparent',
                                        boxShadow:
                                          editing === draft_id
                                            ? '0 0 0 0.2rem inset rgb(202, 198, 230)'
                                            : '',
                                        color: '#0a004e',
                                        fontWeight: '700',
                                      }}
                                      onClick={(e) => {
                                        if (editing && editing !== draft_id) {
                                          setEditing(draft_id)
                                        }
                                        e.stopPropagation()
                                      }}
                                      defaultValue={setting.id || draft_id}
                                      // value={setting.name || ''}
                                      onChange={(e) =>
                                        setDraft(
                                          { id: e.target.value },
                                          draft_id
                                        )
                                      }
                                    />
                                  </Col>
                                  <Col
                                    className="d-flex h-100 data-title"
                                    data-title="專案名稱"
                                  >
                                    <Form.Control
                                      className="my-auto h-75 py-0 fw-regular fs-7 text-dai text-center"
                                      style={{
                                        backgroundColor: 'transparent',
                                        borderColor: 'transparent',
                                        boxShadow:
                                          editing === draft_id
                                            ? '0 0 0 0.2rem inset rgb(202, 198, 230)'
                                            : '',
                                        color: '#0a004e',
                                        fontWeight: '700',
                                      }}
                                      onClick={(e) => {
                                        if (editing && editing !== draft_id) {
                                          setEditing(draft_id)
                                        }
                                        e.stopPropagation()
                                      }}
                                      defaultValue={
                                        setting.name ||
                                        `專案${setting.id || draft_id}`
                                      }
                                      // value={setting.name || ''}
                                      onChange={(e) =>
                                        setDraft(
                                          { name: e.target.value },
                                          draft_id
                                        )
                                      }
                                    />
                                  </Col>
                                  <Col
                                    className="d-flex data-title"
                                    data-title="備註"
                                  >
                                    <Form.Control
                                      className="my-auto h-75 py-0 fw-regular fs-7 text-dai text-center"
                                      style={{
                                        backgroundColor: 'transparent',
                                        borderColor: 'transparent',
                                        boxShadow:
                                          editing === draft_id
                                            ? '0 0 0 0.2rem inset rgb(202, 198, 230)'
                                            : '',
                                        color: '#0a004e',
                                        fontWeight: '700',
                                      }}
                                      placeholder={
                                        editing === draft_id
                                          ? '編輯備註...'
                                          : ''
                                      }
                                      defaultValue={setting.remark || ''}
                                      // value={setting.name || ''}
                                      onClick={(e) => {
                                        if (editing && editing !== draft_id) {
                                          setEditing(draft_id)
                                        }
                                        e.stopPropagation()
                                      }}
                                      onChange={(e) =>
                                        setDraft(
                                          { remark: e.target.value },
                                          draft_id
                                        )
                                      }
                                    />
                                  </Col>
                                  <Col
                                    lg={2}
                                    className="d-flex data-title"
                                    data-title="建立時間"
                                  >
                                    <p className="m-lg-auto mb-0">
                                      {moment(created_on)
                                        .tz('Asia/Taipei')
                                        .format('yyyy-MM-DD HH:mm')}
                                    </p>
                                  </Col>
                                  <Col
                                    lg={2}
                                    className="d-flex data-title"
                                    data-title="更新時間"
                                  >
                                    <p className="m-lg-auto mb-0">
                                      {moment(updated_on)
                                        .tz('Asia/Taipei')
                                        .format('yyyy-MM-DD HH:mm')}
                                    </p>
                                  </Col>
                                  <div className="controlCol">
                                    <Button
                                      className="btn-hover-dai my-auto"
                                      onClick={(e) => {
                                        setEditing(
                                          editing && draft_id === editing
                                            ? ''
                                            : draft_id
                                        )
                                        e.stopPropagation()
                                      }}
                                      title="編 輯"
                                    >
                                      <FontAwesomeIcon
                                        icon={
                                          editing === draft_id
                                            ? faCheckSquare
                                            : faEdit
                                        }
                                        style={{
                                          cursor: 'pointer',
                                        }}
                                        className="m-auto fs-5"
                                      />
                                    </Button>
                                    <Button
                                      className="btn-hover-dai my-auto"
                                      onClick={(e) => {
                                        setshowWarn(draft_id)
                                        e.stopPropagation()
                                      }}
                                      title="刪 除"
                                    >
                                      <FontAwesomeIcon
                                        icon={faTrashAlt}
                                        style={{
                                          cursor: 'pointer',
                                        }}
                                        className="my-auto fs-5"
                                      />
                                    </Button>
                                    <Button
                                      className="btn-hover-dai my-auto"
                                      onClick={async (e) => {
                                        e.stopPropagation()
                                        setloading(true)
                                        await file.makeFile(
                                          setting,
                                          [
                                            'module1',
                                            'module2',
                                            'module3',
                                            'module4',
                                          ],
                                          async (downloadFunc) => {
                                            const delay = (ms) =>
                                              new Promise((resolve) =>
                                                setTimeout(resolve, ms)
                                              )
                                            await delay(5000)

                                            setloading((prevState) => {
                                              if (prevState) downloadFunc()
                                              return false
                                            })
                                          }
                                        )
                                      }}
                                      title="匯出專案"
                                    >
                                      <FontAwesomeIcon
                                        icon={faCloudArrowDown}
                                        style={{
                                          cursor: 'pointer',
                                        }}
                                        className="my-auto fs-5"
                                      />
                                    </Button>
                                    <Button className="btn-hover-dai my-auto">
                                      <FontAwesomeIcon
                                        icon={faBars}
                                        style={
                                          {
                                            // cursor: 'grabbing',
                                          }
                                        }
                                        className="my-auto fs-5"
                                        // onClick={() => handleDraftDelete(draft_id)}
                                        title="排 序"
                                      />
                                    </Button>
                                  </div>
                                </Row>
                              </div>
                            )}
                          </Draggable>
                        )
                      )
                  ) : (
                    <Row>
                      <Col>專案名</Col>
                      <Col>狀態</Col>
                      <Col>建立日期</Col>
                      <Col>更新時間</Col>
                      <Col>備註</Col>
                      <Col>操作</Col>
                    </Row>
                  )}
                  {dropProvided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </>
      ) : (
        <>
          <div className="d-flex" style={{ height: '65%' }}>
            <Image
              className="mt-auto mx-auto"
              src={logoFull}
              style={{ height: '25rem', width: 'auto' }}
            />
          </div>
          <div className="d-flex w-100 mb-auto" style={{ height: '35%' }}>
            <Form className="py-3 px-5 mx-auto d-flex flex-column">
              {fields.map((field) => (
                <Form.Group key={field.name} className="d-flex mb-2">
                  {/* <Form.Label>{field.label}</Form.Label> */}
                  {field.type === 'password' ? (
                    <InputGroup
                      id="defaultBorder"
                      className="rounded input-group-transparent-addon w-100"
                    >
                      <Form.Control
                        name={field.name}
                        type={reveal ? 'text' : field.type}
                        onChange={onDataChange}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter' && !e.isComposing) handleLogin()
                        }}
                        placeholder={field.placeholder}
                      />
                      <InputGroup.Text>
                        <FontAwesomeIcon
                          className="fs-6"
                          style={{
                            right: '10',
                            top: '50',
                            bottom: '50',
                            cursor: 'pointer',
                          }}
                          title={reveal ? '點擊以隱藏密碼' : '點擊以顯示密碼'}
                          icon={reveal ? faEye : faEyeSlash}
                          onClick={() => setReveal(!reveal)}
                        />
                      </InputGroup.Text>
                    </InputGroup>
                  ) : (
                    <Form.Control
                      name={field.name}
                      type={field.type}
                      onChange={onDataChange}
                      placeholder={field.placeholder}
                    />
                  )}
                </Form.Group>
              ))}
              <LoadingButton
                className="mx-auto my-2"
                variant="outline-dai"
                onClick={handleLogin}
                btnText="登入"
              />
              <div className="d-flex">
                <span
                  className="w-100 mx-auto small"
                  style={{
                    cursor: 'pointer',
                    textDecoration: 'underline',
                  }}
                  onClick={() => navigate('/register')}
                  aria-hidden
                >
                  註冊
                </span>
              </div>
            </Form>
          </div>
        </>
      )}
      <Warn
        setting={{
          show: showWarn,
          handleClose: (value) => {
            if (value) handleDraftDelete(value)
            setshowWarn(false)
          },
        }}
      />
    </Container>
  )
}

Warn.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default Home
