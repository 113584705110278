import React from 'react'
import Modal from 'react-bootstrap/Modal'
import PropTypes from 'prop-types'
import { Row, Col } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRotateLeft } from '@fortawesome/free-solid-svg-icons'

function RecordModel({ setting }) {
  const { showModal, closeRecord = () => {} } = setting

  return (
    <Modal show={showModal} onHide={closeRecord} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>分析紀錄</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="m-0">
          <Col xs="6" className="py-1 border-bottom">
            標題
          </Col>
          <Col xs="4" className="py-1 border-bottom">
            發佈時間
          </Col>
          <Col xs="2" className="py-1 text-end border-bottom">
            &nbsp;
          </Col>
        </Row>
        {[
          {
            id: 1,
            title: 'UI調整',
            publishDate: '2024/02/02 上午10:04',
          },
          {
            id: 2,
            title: 'UI調整',
            publishDate: '2024/02/01 上午09:00',
          },
          {
            id: 3,
            title: 'UI調整',
            publishDate: '2024/01/31 上午09:00',
          },
          {
            id: 4,
            title: 'UI調整',
            publishDate: '2024/01/30 上午09:00',
          },
        ].map(({ id, title, publishDate }) => (
          <Row
            className="m-0 hover-bg"
            key={id}
            role="button"
            onClick={() => closeRecord()}
          >
            <Col xs="6" className="py-2 border-bottom">
              {title}
            </Col>
            <Col xs="4" className="py-2 border-bottom">
              {publishDate}
            </Col>
            <Col xs="2" className="py-2 text-end border-bottom">
              <FontAwesomeIcon icon={faRotateLeft} />
            </Col>
          </Row>
        ))}
      </Modal.Body>
    </Modal>
  )
}

RecordModel.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default RecordModel
