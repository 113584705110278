import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { ProgressBar, Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons'
import Warn from './Warn'

function ProcessBar({ setting }) {
  const { show, datalist, processDelete } = setting
  const [showAll, setShowAll] = useState(true)
  const [showWarn, setShowWarn] = useState({
    show: false,
    func: () => {},
  })

  return (
    <div
      className={showAll ? 'processWrap' : 'processWrap small'}
      style={{
        pointerEvents: show ? 'auto' : 'none',
        visibility: show ? 'visible' : 'hidden',
      }}
    >
      <div className="processHeader">
        <h5 className="text-dai fs-4 my-auto ms-2">資料生成中...</h5>
        <Button
          type="button"
          variant="progress-toggle"
          onClick={() => setShowAll(!showAll)}
        >
          <FontAwesomeIcon icon={showAll ? faChevronDown : faChevronUp} />
        </Button>
      </div>
      <div className="processBody">
        <div className="wrap">
          {datalist.map(({ title }) => (
            <div className="text-start" key={title}>
              <div className="processTitle">{title}</div>
              <div className="processBar">
                <ProgressBar className="rounded-pill">
                  <ProgressBar
                    animated
                    style={{
                      backgroundColor: '#0a004f',
                    }}
                    now="10"
                    label="10%"
                  />
                  <ProgressBar
                    now="100"
                    style={{
                      backgroundColor: '#e2e4e8',
                    }}
                  />
                </ProgressBar>
                <span
                  className="text-dai fs-8"
                  style={{
                    textDecoration: 'underline',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    setShowWarn({
                      show: true,
                      func: () => processDelete(title),
                    })
                  }}
                  aria-hidden
                >
                  取消
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Warn
        setting={{
          show: showWarn.show,
          title: '確定要取消流程？',
          handleClose: (value) => {
            if (value) showWarn.func()
            setShowWarn({
              show: false,
              func: () => {},
            })
          },
        }}
      />
    </div>
  )
}

ProcessBar.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default ProcessBar
