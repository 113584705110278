/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Row, Col, Form, Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCirclePlus, faTimes } from '@fortawesome/free-solid-svg-icons'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import Warn from './Warn'
// import FloatInput from './FloatInput'
import AutoHeightText from './AutoHeightText'

function ColorForm({ setting }) {
  const {
    label,
    engLabel,
    color,
    bgcolor,
    module = {},
    handleCardAdd,
    handleCardDelete,
    handleInputChange,
    handleCardResort = () => {},
  } = setting

  const [showWarn, setshowWarn] = useState({
    show: false,
    func: () => {},
  })

  // const [floatSetting, setfloatSetting] = useState({
  //   visibility: 'hidden',
  //   top: '0px',
  //   left: '0px',
  //   width: '0px',
  //   value: '',
  //   onBlur: () => {},
  //   onChange: () => {},
  // })

  const onDragEnd = (result) => {
    if (!result.destination) {
      return
    }

    handleCardResort(
      label,
      result.source.index + parseInt(result.source.droppableId, 10) * 3,
      result.destination.index +
        parseInt(result.destination.droppableId, 10) * 3
    )
  }
  const grid = 0
  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: 'none',
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,
    cursor: isDragging ? 'grab' : 'pointer',
    ...draggableStyle,
  })

  const lists = useMemo(() => {
    if (!module[label]) return [[]]
    const fill = module[label].length % 1 ? 1 - (module[label].length % 1) : 0
    const res = []
    const filled = module[label].concat(
      Array.from({ length: fill }).fill([{ fill: true }, false, false, false])
    )
    for (let i = 0; i < filled.length; i += 3) res.push(filled.slice(i, i + 3))
    return res
  }, [module[label]])

  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? 'transparent' : 'transparent',
    padding: grid,
    width: '100%',
  })

  return (
    <>
      <Row
        className="list-row print-break"
        style={{
          border: color,
          backgroundColor: bgcolor,
        }}
      >
        <Col xs={12} xl={2} className="d-flex tabel-tabel">
          <p className="m-auto">
            {label} {engLabel}
          </p>
        </Col>
        <Col className="d-flex h-100 flex-column">
          <DragDropContext onDragEnd={onDragEnd}>
            {lists.map((list, index) => (
              <Droppable
                key={index}
                droppableId={`${index}`}
                direction="vertical"
              >
                {(dropProvided, dropSnapshot) => (
                  <div
                    {...dropProvided.droppableProps}
                    ref={dropProvided.innerRef}
                    style={getListStyle(dropSnapshot.isDraggingOver)}
                    className="d-flex flex-wrap"
                  >
                    {list.map(([tag, content, memo, id], i) => (
                      <Draggable key={`${id}`} draggableId={`${id}`} index={i}>
                        {(dragProvided, dragSnapshot) => (
                          <div
                            className="w-100"
                            ref={dragProvided.innerRef}
                            {...dragProvided.draggableProps}
                            {...dragProvided.dragHandleProps}
                            style={{
                              ...getItemStyle(
                                dragSnapshot.isDragging,
                                dragProvided.draggableProps.style
                              ),
                            }}
                          >
                            <Row key={i} className="list-item w-100">
                              <Col
                                className="d-flex align-items-start"
                                xs={6}
                                xl={2}
                              >
                                <p className="number-col text-start">
                                  {i + 1}.
                                </p>
                                <Form.Control
                                  className=""
                                  value={tag}
                                  // onDoubleClick={(e) => {
                                  //   const { offsetTop, offsetLeft, offsetWidth } = e.target
                                  //   setfloatSetting({
                                  //     top: offsetTop,
                                  //     left: offsetLeft,
                                  //     width: offsetWidth,
                                  //     visibility: 'visible',
                                  //     value: tag,
                                  //     onBlur: () =>
                                  //       setfloatSetting({
                                  //         ...floatSetting,
                                  //         visibility: 'hidden',
                                  //       }),
                                  //     onChange: (value) => {
                                  //       handleInputChange(label, i, [value, content, memo, id])
                                  //     },
                                  //   })
                                  // }}
                                  onChange={(e) =>
                                    handleInputChange(label, i, [
                                      e.target.value,
                                      content,
                                      memo,
                                      id,
                                    ])
                                  }
                                />
                              </Col>
                              <Col className="d-flex">
                                <AutoHeightText
                                  setting={{
                                    onChange: (value) => {
                                      handleInputChange(label, i, [
                                        tag,
                                        value,
                                        memo,
                                        id,
                                      ])
                                    },
                                    value: content,
                                  }}
                                />
                                {/* <Form.Control
                                  className="py-0 fw-regular fs-7 text-dai"
                                  style={{
                                    backgroundColor: 'transparent',
                                    resize: 'none',
                                    overflowY: 'hidden',
                                  }}
                                  as="textarea"
                                  value={content}
                                  onDoubleClick={(e) => {
                                    const { offsetTop, offsetLeft, offsetWidth } = e.target
                                    setfloatSetting({
                                      top: offsetTop,
                                      left: offsetLeft,
                                      width: offsetWidth,
                                      visibility: 'visible',
                                      value: content,
                                      onBlur: () =>
                                        setfloatSetting({
                                          ...floatSetting,
                                          visibility: 'hidden',
                                        }),
                                      onChange: (value) => {
                                        handleInputChange(label, i, [tag, value, memo, id])
                                      },
                                    })
                                  }}
                                  onChange={(e) => {
                                    handleInputChange(label, i, [tag, e.target.value, memo, id])
                                  }}
                                /> */}
                              </Col>
                              <div className="list-control">
                                <FontAwesomeIcon
                                  icon={faTimes}
                                  style={{
                                    cursor: 'pointer',
                                  }}
                                  className="ms-auto mt-auto fs-7 text-dai-light print-none"
                                  title="刪除"
                                  onClick={() =>
                                    setshowWarn({
                                      show: true,
                                      func: () => handleCardDelete(label, i),
                                    })
                                  }
                                />
                              </div>
                            </Row>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {dropProvided.placeholder}
                  </div>
                )}
              </Droppable>
            ))}
          </DragDropContext>
          <Row>
            <Col>
              <Button
                variant="add"
                className="w-100"
                onClick={() => handleCardAdd(label)}
              >
                <FontAwesomeIcon
                  icon={faCirclePlus}
                  style={{
                    cursor: 'pointer',
                  }}
                  className="ms-auto my-auto fs-7 text-dai-light print-none"
                  title="新增"
                />
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      {/* <FloatInput setting={floatSetting} /> */}
      <Warn
        setting={{
          show: showWarn.show,
          handleClose: (value) => {
            if (value) showWarn.func()
            setshowWarn({
              show: false,
              func: () => {},
            })
          },
        }}
      />
    </>
  )
}

function SuggestForm({ setting }) {
  return (
    <Row className="wrapper pt-2 ps-3">
      {[
        {
          label: '政治',
          engLabel: '(Political)',
          key: 'Political',
          color: '1px solid rgb(35, 61, 99, 0.7)',
          bgcolor: 'rgb(35, 61, 99, 0.1)',
        },
        {
          label: '經濟',
          engLabel: '(Economic)',
          key: 'Economic',
          color: '1px solid rgb(51, 107, 139, 0.7)',
          bgcolor: 'rgb(51, 107, 139, 0.1)',
        },
        {
          label: '社會',
          engLabel: '(Social)',
          key: 'Social',
          color: '1px solid rgb(234, 112, 11, 0.7)',
          bgcolor: 'rgb(234, 112, 11, 0.1)',
        },
        {
          label: '技術',
          engLabel: '(Technological)',
          key: 'Technological',
          color: '1px solid rgb(240, 181, 31, 0.7)',
          bgcolor: 'rgb(240, 181, 31, 0.1)',
        },
        {
          label: '環境',
          engLabel: '(Environmental)',
          key: 'Environmental',
          color: '1px solid rgb(131, 152, 77, 0.7)',
          bgcolor: 'rgb(131, 152, 77, 0.1)',
        },
        {
          label: '法律',
          engLabel: '(Legal)',
          key: 'Legal',
          color: '1px solid rgb(66, 63, 63, 0.7)',
          bgcolor: 'rgb(66, 63, 63, 0.1)',
        },
      ].map(({ key, label, engLabel, color, bgcolor }) => (
        <ColorForm
          key={key}
          setting={{
            label,
            engLabel,
            color,
            bgcolor,
            ...setting,
          }}
        />
      ))}
    </Row>
  )
}

ColorForm.propTypes = {
  setting: PropTypes.shape().isRequired,
}

SuggestForm.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default SuggestForm
