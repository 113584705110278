/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import * as allCurves from '@visx/curve'
import { GridRows, GridColumns } from '@visx/grid'
import { Bar, LinePath } from '@visx/shape'
import { Group } from '@visx/group'
import { scaleBand, scaleLinear, scalePoint } from '@visx/scale'
import { Drag } from '@visx/drag'

const verticalMargin = 0
// accessors
const getLetter = (d) => d.event
const getLetterFrequency = (d) => Number(d.emotion)

function XYDrag({ setting }) {
  const { emotions, handleEmotionChange, width = 1000, height = 1000 } = setting
  const xMax = width
  const yMax = height - verticalMargin

  const [data, setData] = useState([
    {
      emotion: parseFloat(emotions[0]),
      event: 1,
    },
    {
      emotion: parseFloat(emotions[1]),
      event: 3,
    },
    {
      emotion: parseFloat(emotions[2]),
      event: 5,
    },
    {
      emotion: parseFloat(emotions[3]),
      event: 7,
    },
    {
      emotion: parseFloat(emotions[4]),
      event: 9,
    },
  ])

  useEffect(() => {
    setData([
      {
        emotion: parseFloat(emotions[0]),
        event: 1,
      },
      {
        emotion: parseFloat(emotions[1]),
        event: 3,
      },
      {
        emotion: parseFloat(emotions[2]),
        event: 5,
      },
      {
        emotion: parseFloat(emotions[3]),
        event: 7,
      },
      {
        emotion: parseFloat(emotions[4]),
        event: 9,
      },
    ])
  }, [emotions])

  const xScale = useMemo(
    () =>
      scaleBand({
        range: [0, xMax],
        round: true,
        domain: [1, 2, 3, 4, 5, 6, 7, 8, 9],
        padding: 0.8,
      }),
    [xMax]
  )

  const lineXScale = useMemo(
    () =>
      scalePoint({
        range: [0, xMax],
        round: true,
        domain: [1, 2, 3, 4, 5, 6, 7, 8, 9],
        padding: 0.86,
      }),
    [xMax]
  )

  const yScale = useMemo(
    () =>
      scaleLinear({
        range: [yMax, 0],
        round: true,
        domain: [-4, 4],
      }),
    [yMax]
  )

  const [draggingItems, setDraggingItems] = useState(
    data.map(({ event, emotion }, i) => ({
      id: i,
      x: event * 100,
      y: emotion,
      radius: 12,
      color: 'transparent',
    }))
  )

  useEffect(() => {
    console.log('this is triggered')
    setDraggingItems(
      data.map(({ event, emotion }) => {
        const x = xScale(event) + xScale.bandwidth() / 2
        const refined = Number.isFinite(parseInt(emotion, 10))
          ? Math.max(Math.min(parseInt(emotion, 10), 2), -2)
          : 0
        const y = height - (height / 8) * (refined + 4)
        return {
          event,
          id: event,
          x,
          y,
          radius: 12,
          color: 'transparent',
        }
      })
    )
  }, [data, width])

  const [isDragging, setIsDragging] = useState(false)
  const [isHover, setIsHover] = useState(false)

  return width < 10 ? null : (
    <svg width={width} height={height}>
      {/* <GradientTealBlue id="teal" /> */}
      <rect width={width} height={height} fill="url(#teal)" rx={0} />
      <GridRows
        scale={yScale}
        width={xMax}
        height={yMax}
        numTicks={4}
        stroke="#eeeeee"
      />
      <GridColumns
        scale={xScale}
        width={xMax}
        height={yMax}
        numTicks={5}
        stroke="#eeeeee"
      />
      <Group top={verticalMargin / 2} width={width}>
        {data.map((d) => {
          const letter = getLetter(d)
          const barWidth = xScale.bandwidth()
          const barHeight = yMax - (yScale(getLetterFrequency(d)) ?? 0)
          const barX = xScale(letter)
          const barY = yMax - barHeight
          return (
            <Bar
              key={`bar-${letter}`}
              x={barX}
              y={barY}
              width={barWidth}
              height={barHeight}
              fill="rgba(23, 233, 217, .5)"
            />
          )
        })}
        <LinePath
          curve={allCurves.curveLinear}
          data={data}
          x={(d) => lineXScale(getLetter(d))}
          y={(d) => yScale(getLetterFrequency(d)) ?? 0}
          stroke="#333"
          strokeWidth={1}
          strokeOpacity={1}
          shapeRendering="geometricPrecision"
          markerMid="url(#marker-circle)"
          markerStart="url(#marker-line)"
          markerEnd="url(#marker-arrow)"
        />
      </Group>
      {draggingItems.map((d) => (
        <Drag
          key={`drag-${d.id}`}
          width={width}
          height={height}
          x={d.x}
          y={d.y}
          onDragStart={() => setIsDragging(d.id)}
          onDragMove={(e) => {
            if (!isDragging) return
            const result = draggingItems
              .map((item) =>
                item.id === d.id ? { ...item, y: item.y + e.dy } : item
              )
              .sort((a, b) => b.id - a.id)
            setData(
              result.map((r) => ({
                emotion: (height - r.y) / (height / 8) - 4,
                event: r.event,
              }))
            )
          }}
          onDragEnd={() => {
            handleEmotionChange(
              data.sort((a, b) => a.event - b.event).map((item) => item.emotion)
            )
            setIsDragging(false)
          }}
        >
          {({ dragStart, dragEnd, dragMove, x, y, dy }) => (
            <React.Fragment key={`dot-${d.id}`}>
              <circle
                cx={x}
                cy={y}
                r={d.radius}
                fill={d.color}
                transform={`translate(0, ${dy})`}
                fillOpacity={1}
                stroke={
                  d.id === isDragging
                    ? '#212529'
                    : d.id === isHover
                    ? '#212529'
                    : 'transparent'
                }
                strokeWidth={2}
                onMouseMove={dragMove}
                onMouseUp={dragEnd}
                onMouseDown={dragStart}
                onMouseEnter={() => setIsHover(d.id)}
                onMouseLeave={() => setIsHover('')}
                onTouchStart={dragStart}
                onTouchMove={dragMove}
                onTouchEnd={dragEnd}
                cursor={d.id === isDragging ? 'grabbing' : 'grab'}
              />
            </React.Fragment>
          )}
        </Drag>
      ))}
    </svg>
  )
}

XYDrag.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default XYDrag
