import React, { useEffect, useMemo, useContext, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import PropTypes from 'prop-types'
import { Row, Col, DropdownButton } from 'react-bootstrap'
import SideNavBar from './SideNavBar'
import TopBar from './TopBar'
import GPTHelper from './GPTHelper'
import Avatar from './Avatar'
import MenuCard from './MeunCard'
import { AuthContext, DraftContext } from './ContextProvider'

function AppWrapper({ children }) {
  const { auth } = useContext(AuthContext)
  const location = useLocation()
  const navigate = useNavigate()
  const { draftId } = useContext(DraftContext)
  const [menuActive, setMenuActive] = useState(false)

  const isInDraft = useMemo(
    () =>
      ['/Module1', '/Module2', '/Module3', '/Module4'].includes(
        location.pathname
      ),
    [location]
  )
  useEffect(() => {
    if (draftId && !isInDraft) {
      navigate('/Module1')
    }
    if ((!auth.authed || !draftId) && isInDraft) {
      navigate('/')
    }
  }, [auth, location, draftId])

  return auth.authed ? (
    <div className="appWrapper">
      <button
        type="button"
        className={`${menuActive ? 'active' : ''} megaMenu`}
        onClick={() => setMenuActive(!menuActive)}
      >
        <FontAwesomeIcon className="" icon={faBars} title="手機版選單" />
      </button>
      <div className={`${menuActive ? 'active' : ''} sideBar`}>
        <SideNavBar setting={{}} />
      </div>
      <div className={`${menuActive ? 'active' : ''} pageWrapper`}>
        {isInDraft && (
          <Row className="pageHeader">
            <TopBar />
          </Row>
        )}
        <div className="account">
          <DropdownButton
            id="dropdown-button-drop-end"
            drop="end"
            title={
              <div className="text-dai fw-bolder pt-3">
                <div
                  style={{
                    height: '50px',
                  }}
                >
                  <Avatar />
                </div>
              </div>
            }
          >
            <MenuCard />
          </DropdownButton>
        </div>
        <Row
          className={`${
            isInDraft ? 'h-86' : 'h-96'
          } p-4 position-relative lg-h-auto`}
        >
          {children}
        </Row>
        <div className="small fw-bold text-dai-light pt-2">
          Copyright &copy; 2024 TDRI. all rights reserved.
        </div>
      </div>
      <GPTHelper setting={{}} />
    </div>
  ) : (
    <div
      className="d-flex position-relative"
      style={{
        height: '100vh',
        width: '100vw',
      }}
    >
      <Col xs={12} className="d-flex flex-column">
        <Row className="flex-fill">{children}</Row>
      </Col>
    </div>
  )
}

AppWrapper.propTypes = {
  children: PropTypes.shape().isRequired,
  //   setting: PropTypes.shape().isRequired,
}

export default AppWrapper
