/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
// import { v4 as uuid } from 'uuid'

const onDragEnd = () => {}

const ITEMS = [
  {
    id: 67,
    content: 'Headline',
  },
  {
    id: 68,
    content: 'Image',
  },
  {
    id: 69,
    content: 'Gallery',
  },
  {
    id: 70,
    content: 'Quote',
  },
]

const grid = 0
const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: 'none',
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,
  background: isDragging ? 'lightgreen' : 'grey',
  ...draggableStyle,
})

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? 'lightblue' : 'lightgrey',
  padding: grid,
  width: 250,
})

function Test() {
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}
          >
            {ITEMS.map((item, index) => (
              <Draggable
                key={`${item.id}`}
                draggableId={`${item.id}`}
                index={index}
              >
                {(dragProvided, dragSnapshot) => (
                  <React.Fragment key={index}>
                    <div
                      ref={dragProvided.innerRef}
                      {...dragProvided.draggableProps}
                      {...dragProvided.dragHandleProps}
                      style={getItemStyle(
                        dragSnapshot.isDragging,
                        dragProvided.draggableProps.style
                      )}
                    >
                      {item.content}
                    </div>
                    {dragSnapshot.isDragging && (
                      <div className="cloneItem">111</div>
                    )}
                  </React.Fragment>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}

export default Test
