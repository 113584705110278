/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons'
import { Row, Col, Card, Form } from 'react-bootstrap'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import AutoHeightText from './AutoHeightText'
import Warn from './Warn'

function ColorCard({ setting }) {
  const {
    label,
    engLabel,
    color,
    bgcolor,
    module = {},
    handleInputChange,
    handleCardAdd,
    handleCardDelete,
    handleCardResort = () => {},
  } = setting

  const [showWarn, setshowWarn] = useState({
    show: false,
    func: () => {},
  })

  const onDragEnd = (result) => {
    if (!result.destination) {
      return
    }

    handleCardResort(
      label,
      result.source.index + parseInt(result.source.droppableId, 10) * 3,
      result.destination.index +
        parseInt(result.destination.droppableId, 10) * 3
    )
  }
  const grid = 8
  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',

    // change background colour if dragging
    // background: isDragging ? 'white' : 'white',

    // styles we need to apply on draggables
    ...draggableStyle,
  })

  const lists = useMemo(() => {
    if (!module[label]) return [[]]
    const fill = module[label].length % 3 ? 3 - (module[label].length % 3) : 0
    const res = []
    const filled = module[label].concat(
      Array.from({ length: fill }).fill([{ fill: true }, false, false, false])
    )
    for (let i = 0; i < filled.length; i += 3) res.push(filled.slice(i, i + 3))
    return res
  }, [module[label]])

  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? 'transparent' : 'transparent',
    padding: grid,
    width: '100%',
  })

  return (
    <Card
      className="w-100 h-100 p-0 text-dai fw-bold"
      style={{
        border: color,
        backgroundColor: bgcolor,
        minHeight: '100%',
      }}
    >
      <Card.Header className="text-center ">
        <span className="text-nowrap">
          {label}（ {engLabel} ）
        </span>
        <FontAwesomeIcon
          icon={faPlus}
          style={{
            cursor: 'pointer',
            right: '20px',
            top: '10px',
          }}
          className="position-absolute print-none"
          onClick={() => handleCardAdd(label)}
          title="新增"
        />
      </Card.Header>
      <DragDropContext onDragEnd={onDragEnd} className="h-100">
        {lists.map((list, index) => (
          <Droppable
            key={index}
            droppableId={`${index}`}
            direction="horizontal"
          >
            {(dropProvided, dropSnapshot) => (
              <div
                {...dropProvided.droppableProps}
                ref={dropProvided.innerRef}
                style={getListStyle(dropSnapshot.isDraggingOver)}
                className="d-flex flex-wrap flex-1"
              >
                {list.map(
                  ([tag, content, memo, id], i) =>
                    !tag.fill && (
                      <Draggable key={`${id}`} draggableId={`${id}`} index={i}>
                        {(dragProvided, dragSnapshot) => (
                          <div
                            ref={dragProvided.innerRef}
                            {...dragProvided.draggableProps}
                            {...dragProvided.dragHandleProps}
                            className="card-item text-dai d-flex flex-column"
                            style={{
                              minHeight: '250px',
                              ...getItemStyle(
                                dragSnapshot.isDragging,
                                dragProvided.draggableProps.style
                              ),
                            }}
                          >
                            <div className="card-body">
                              <div className="card-header">
                                <Form.Control
                                  className="my-auto py-0 fw-regular fs-7 text-dai text-center"
                                  style={{
                                    backgroundColor: 'transparent',
                                    color: '#0a004e',
                                  }}
                                  value={tag}
                                  onChange={(e) =>
                                    handleInputChange(label, index * 3 + i, [
                                      e.target.value,
                                      content,
                                      memo,
                                      id,
                                    ])
                                  }
                                />
                                <FontAwesomeIcon
                                  icon={faTimes}
                                  style={{
                                    cursor: 'pointer',
                                  }}
                                  className="text-dai-light rightTop print-none"
                                  onClick={() =>
                                    setshowWarn({
                                      show: true,
                                      func: () =>
                                        handleCardDelete(label, index * 3 + i),
                                    })
                                  }
                                  title="刪除"
                                />
                              </div>
                              <AutoHeightText
                                setting={{
                                  onChange: (value) => {
                                    handleInputChange(label, index * 3 + i, [
                                      tag,
                                      value,
                                      memo,
                                      id,
                                    ])
                                  },
                                  value: content,
                                }}
                              />
                            </div>
                          </div>
                        )}
                      </Draggable>
                    )
                )}
                {dropProvided.placeholder}
              </div>
            )}
          </Droppable>
        ))}
      </DragDropContext>
      <Warn
        setting={{
          show: showWarn.show,
          handleClose: (value) => {
            if (value) showWarn.func()
            setshowWarn({
              show: false,
              func: () => {},
            })
          },
        }}
      />
    </Card>
  )
}

function SuggestCard({ setting }) {
  return (
    <Row className="wrapper pt-2 ps-3">
      {[
        {
          label: '政治',
          engLabel: 'Political',
          key: 'Political',
          bgcolor: 'rgb(35, 61, 99, 0.1)',
          color: '1px solid rgb(35, 61, 99, 0.7)',
        },
        {
          label: '經濟',
          engLabel: 'Economic',
          key: 'Economic',
          bgcolor: 'rgb(51, 107, 139, 0.1)',
          color: '1px solid rgb(51, 107, 139, 0.7)',
        },
        {
          label: '社會',
          engLabel: 'Social',
          key: 'Social',
          bgcolor: 'rgb(234, 112, 11, 0.1)',
          color: '1px solid rgb(234, 112, 11, 0.7)',
        },
        {
          label: '技術',
          engLabel: 'Technological',
          key: 'Technological',
          bgcolor: 'rgb(240, 181, 31, 0.1)',
          color: '1px solid rgb(240, 181, 31, 0.7)',
        },
        {
          label: '環境',
          engLabel: 'Environmental',
          key: 'Environmental',
          bgcolor: 'rgb(131, 152, 77, 0.1)',
          color: '1px solid rgb(131, 152, 77, 0.7)',
        },
        {
          label: '法律',
          engLabel: 'Legal',
          key: 'Legal',
          bgcolor: 'rgb(66, 63, 63, 0.1)',
          color: '1px solid rgb(66, 63, 63, 0.7)',
        },
      ].map(({ key, label, engLabel, color, bgcolor }) => (
        <Col xs={12} lg={6} key={key} className="p-1 print-break">
          <ColorCard
            setting={{
              label,
              engLabel,
              color,
              bgcolor,
              ...setting,
            }}
          />
        </Col>
      ))}
    </Row>
  )
}

ColorCard.propTypes = {
  setting: PropTypes.shape().isRequired,
}

SuggestCard.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default SuggestCard
