/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/destructuring-assignment */
import React, { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import { Row, Col, Form, Button, ButtonGroup } from 'react-bootstrap'
import Modal from 'react-bootstrap/Modal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCirclePlus, faPlus } from '@fortawesome/free-solid-svg-icons'
import { DraftContext } from './ContextProvider'
import ProcessBar from './ProcessBar'
import RecordModel from './RecordModel'

function PromptOptions({ setting }) {
  const { searchTargets } = setting
  const location = useLocation()

  const [showProcess, setShowProcess] = useState(false)

  // Modal
  const [showModal, setShowModal] = useState(false)

  const modalClose = () => setShowModal(false)
  const modalShow = () => setShowModal(true)

  // const { auth } = useContext(AuthContext)
  const { draftId, module1, module2, module3, module4, dataList, setDataList } =
    useContext(DraftContext)

  const modules = {
    '/Module1': module1,
    '/Module2': module2,
    '/Module3': module3,
    '/Module4': module4,
  }

  const pathnameConverter = {
    '/Module1': '/Module1',
    '/Module2': '/Module2',
    '/Module3': '/Module4',
    '/Module4': '/Module3',
  }

  // const records = useMemo(() => {
  //   if (!auth.setting.records) return []
  //   return auth.setting.records.filter(
  //     ({ module }) => module === location.pathname
  //   )
  // }, [auth.setting.records, location.pathname])

  const [data, setData] = useState({
    draftId,
  })

  const processDelete = (label) => {
    setDataList(dataList.filter((item) => item.title !== label))
  }

  useEffect(() => {
    const options = searchTargets[location.pathname]
    if (!options) return
    setData(
      options.reduce(
        (prev, { name }, i) => ({
          ...prev,
          [name]: modules[pathnameConverter[location.pathname]]
            ? modules[pathnameConverter[location.pathname]][name]
            : '',
          [`field${i}Selections`]: [],
        }),
        {
          draftId,
        }
      )
    )
  }, [location.pathname])

  const handleDataChange = (e) =>
    setData((prevState) => ({ ...prevState, [e.target.name]: e.target.value }))

  return (
    <Row>
      {searchTargets[location.pathname] ? (
        <div>
          <Button
            variant="outline-dai"
            id="button-addon2"
            title="新增"
            className="topbar-btn w-100"
            onClick={modalShow}
          >
            <FontAwesomeIcon icon={faPlus} /> 新增分析
          </Button>
          <Modal show={showModal} onHide={modalClose} centered size="lg">
            <Modal.Header closeButton>
              <Modal.Title>新增分析</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {searchTargets[location.pathname].map(
                ({ label, name, placeholder, method, xs }) => (
                  <Col xs={xs} key={name}>
                    <div className="px-0 pb-3 searchBar">
                      {label && (
                        <Form.Label className="my-auto pb-1 text-nowrap">
                          {label}
                        </Form.Label>
                      )}
                      <Form.Control
                        as="textarea"
                        rows={3}
                        placeholder={placeholder}
                        onChange={(e) => {
                          handleDataChange({
                            target: {
                              name,
                              value: e.target.value,
                            },
                          })
                        }}
                      />
                      {method && (
                        <div className="modal-footer justify-content-center">
                          <Button
                            variant="outline-dai"
                            id="button-addon2"
                            title="搜 尋"
                            onClick={() => {
                              method(data)
                              setShowModal(false)
                              setShowProcess(true)
                              setDataList((prevState) => [
                                ...prevState,
                                { title: modules[location.pathname].step },
                              ])
                            }}
                            // disabled={Object.keys(data).some(
                            //   (key) => !data[key]
                            // )}
                          >
                            Create&ensp;
                            <FontAwesomeIcon icon={faCirclePlus} />
                          </Button>
                        </div>
                      )}
                    </div>
                  </Col>
                )
              )}
            </Modal.Body>
          </Modal>
          <ProcessBar
            setting={{
              show: showProcess,
              datalist: dataList,
              processDelete,
            }}
          />
        </div>
      ) : (
        <Col />
      )}
    </Row>
  )
}

function TopBar() {
  const sendMessage = () => {}
  const { draftId, model, handleModelChange } = useContext(DraftContext)

  const [showRecord, setShowRecord] = useState(false)

  const searchTargets = {
    '/Module1': [
      {
        label: '產品/服務',
        name: 'option1',
        placeholder: '請列出最近幾年推出的超跑種車種',
      },
      {
        label: '地區',
        name: 'option2',
        placeholder: '請限制在美國、日本、義大利等地區',
        method: (value) => sendMessage('module1', value),
      },
    ],
    '/Module2': [
      {
        label: '產品/服務',
        name: 'option1',
        placeholder: '超跑',
      },
      {
        label: '地區',
        name: 'option2',
        placeholder: '美國',
        method: (value) => sendMessage('module2', value),
      },
    ],
    '/Module4': [
      {
        label: '角色/職業',
        name: 'option1',
        placeholder: '工程師',
        // xs: '4',
      },
      {
        label: '外型與穿著',
        name: 'option2',
        placeholder: '西裝',
        method: (value) => sendMessage('module3', value),
        // xs: '4',
      },
    ],
    '/Module3': [
      {
        name: 'option1',
        label: '角色/職業',
        placeholder: '國安局特工',
      },
      {
        name: 'option2',
        label: '任務',
        placeholder: ' 國家機密資料保存與維護',
        method: (value) => sendMessage('module4', { ...value, draftId }),
      },
    ],
  }
  return (
    <div className="d-flex flex-wrap py-2 align-items-start">
      <div className="headerGroup">
        <PromptOptions
          setting={{
            searchTargets,
          }}
        />
      </div>
      <div className="headerGroup">
        <ButtonGroup className="w-100 h-100" aria-label="Basic">
          <Button
            // disabled={model === 'gpt-3.5-turbo'}
            onClick={() => handleModelChange('gpt-3.5-turbo')}
            className="w-50 text-dai d-flex topbar-btn"
            variant="outline-dai"
            size="sm"
            active={model === 'gpt-3.5-turbo'}
          >
            <span className="fw-bold fs-6 m-auto">GPT-3</span>
          </Button>
          <Button
            // disabled={model === 'gpt-4'}
            onClick={() => handleModelChange('gpt-4')}
            className="w-50 text-dai d-flex topbar-btn"
            variant="outline-dai"
            size="sm"
            active={model === 'gpt-4'}
          >
            <span className="fw-bold fs-6 m-auto">GPT-4</span>
          </Button>
        </ButtonGroup>
      </div>
      <div className="headerGroup">
        <ButtonGroup className="w-100 h-100" aria-label="Basic">
          <Button
            className="w-50 text-dai d-flex topbar-btn"
            variant="outline-dai"
            size="sm"
            onClick={() => {
              setShowRecord(true)
            }}
          >
            <span className="fw-bold fs-6 m-auto">分析紀錄</span>
          </Button>
        </ButtonGroup>
      </div>
      <RecordModel
        setting={{
          showModal: showRecord,
          closeRecord: () => {
            setShowRecord(false)
          },
        }}
      />
    </div>
  )
}

PromptOptions.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default TopBar
