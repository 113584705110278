import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Form } from 'react-bootstrap'

function AutoHeightText({ setting }) {
  const { value, onChange = () => {} } = setting
  const [temp, setTemp] = useState(value)
  const textAreaRef = useRef(null)

  const resizeTextArea = () => {
    textAreaRef.current.style.height = 'auto'
    textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`
  }

  useEffect(resizeTextArea, [temp])
  return (
    <div className="textarea-group">
      <div className="print-textarea">{temp}</div>
      <Form.Control
        ref={textAreaRef}
        className=""
        style={{
          resize: 'none',
          overflowY: 'hidden',
          border: 'none',
        }}
        value={temp}
        as="textarea"
        onChange={(e) => {
          setTemp(e.target.value)
          onChange(temp)
        }}
      />
    </div>
  )
}

AutoHeightText.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default AutoHeightText
